import type { FC } from 'react';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import type { CoverageInsightCardProps } from './CoverageInsightCard.type.ts';
import { useNavigate, useParams } from 'react-router';
import useAccountQuery from '../../../api/account/use-account-query.ts';
import ImageNarrow from '../../../assets/svg/coverage-insight-image-narrow.svg?react';
import ImageWide from '../../../assets/svg/coverage-insight-image-wide.svg?react';
import OfferCard from '../../../components/OfferCard/OfferCard.tsx';
import ROUTES from '../../../constants/routes.ts';
import useAnalytics from '../../../hooks/use-analytics.ts';
import useDpNavigate from '../../../hooks/use-dp-navigate.ts';
import useResponsive from '../../../hooks/use-responsive.ts';
import Button from '../../../shared/components/Button/Button.tsx';
import { pageButtonCSS } from '../../../styles/layout.style.ts';
import THEME from '../../../styles/theme.ts';
import { AnalyticLocation, AnalyticPageKey } from '../../../types/analytics.type.ts';
import { DpCta } from '../../../types/digital-profile.type.ts';
import { containerCSS, imageCSS } from './CoverageInsightCard.style.ts';

const CoverageInsightCard: FC<CoverageInsightCardProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: accountData } = useAccountQuery(gid);
  const profileCompleted = !!accountData?.account.profile_completed_at;

  const navigate = useNavigate();
  const { isDesktop } = useResponsive();
  const navigateToDp = useDpNavigate();
  const { track } = useAnalytics();

  const onActionClick = () => {
    if (profileCompleted) {
      track('Insights Navigation Clicked', {
        target_page: AnalyticPageKey.ReplacementCost,
      });
      navigate(ROUTES.replacementCost.replace(':gid', gid));
    }
    else {
      track('Unlock Portal Data Requested', {
        page: AnalyticPageKey.Insights,
        location: AnalyticLocation.CoverageInsightCard,
      });
      navigateToDp(DpCta.CoverageLevel, AnalyticPageKey.ReplacementCost);
    }
  };

  return (
    <div css={customCSS}>
      <OfferCard
        customCSS={containerCSS}
        title="Did you know?"
        message={(
          <>
            There are many factors that impact replacement costs, including material, labor cost,
            inflation, home location, home age and construction method. For these reasons it is
            important for you to make sure that the coverage meets your needs.
          </>
        )}
        backgroundColor={THEME.color.azure95}
        messageColor={THEME.color.azure68}
      >
        {isDesktop ? <ImageWide css={imageCSS} /> : <ImageNarrow css={imageCSS} />}
      </OfferCard>
      <Button customCSS={pageButtonCSS} variant="secondary" onClick={onActionClick}>
        {profileCompleted ? 'Check your coverage level' : 'Unlock your coverage level' }
      </Button>
    </div>
  );
};

export default CoverageInsightCard;
