import type { QuestionProps } from '../../../components/Question/Question.type.ts';
import { QuestionType } from '../../../components/Question/Question.type.ts';
import { PERSON_AGE_VALIDATION, REQUIRED_VALIDATION } from '../../../utils/question-validations.util.ts';

export const SIGN_UP_FORM_SCHEMA: QuestionProps[] = [
  {
    type: QuestionType.Text,
    questionKey: 'first_name',
    label: 'First Name',
    placeholder: 'Jane',
    validations: [REQUIRED_VALIDATION],
    gridWidth: 4,
    dense: true,
    trackingForbidden: true,
  },
  {
    type: QuestionType.Text,
    questionKey: 'last_name',
    label: 'Last Name',
    placeholder: 'Doe',
    validations: [REQUIRED_VALIDATION],
    gridWidth: 4,
    dense: true,
    trackingForbidden: true,
  },
  {
    type: QuestionType.Date,
    questionKey: 'date_of_birth',
    label: 'Date of Birth',
    validations: [REQUIRED_VALIDATION, ...PERSON_AGE_VALIDATION],
    gridWidth: 4,
    dense: true,
    trackingForbidden: true,
  },
  {
    type: QuestionType.Email,
    questionKey: 'email',
    label: (
      <>
        Email
        <span>(this will be your username)</span>
      </>
    ),
    placeholder: 'xxxxxx@xxxxx.com',
    validations: [REQUIRED_VALIDATION],
    gridWidth: 12,
    dense: true,
    trackingForbidden: true,
  },
  {
    type: QuestionType.Address,
    questionKey: 'address',
    label: 'Primary Address',
    placeholder: '123 Fake St, City, State 12345',
    validations: [REQUIRED_VALIDATION],
    gridWidth: 12,
    dense: true,
    trackingForbidden: true,
    hint: 'This address should match the address of your property that is insured.',
  },
  {
    type: QuestionType.Phone,
    questionKey: 'phone',
    label: 'Phone Number',
    validations: [REQUIRED_VALIDATION],
    gridWidth: 12,
    dense: true,
    trackingForbidden: true,
    placeholder: '(123) 456-7890',
  },
];
