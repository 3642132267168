import type { FC } from 'react';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import { Divider } from '@mui/material';

import { useState } from 'react';
import { useParams } from 'react-router';
import useAccountQuery from '../../api/account/use-account-query.ts';
import useQuotesQuery from '../../api/quotes/use-quotes-query.ts';
import { NonOptionalPolicyInfoSchema } from '../../api/score/score-query.schema.ts';
import useScoreQuery from '../../api/score/use-score-query.ts';
import EditIcon from '../../assets/svg/edit.svg?react';
import ProtectionIconLarge from '../../assets/svg/factors/protection-factor-large.svg?react';
import ProtectionIconSmall from '../../assets/svg/factors/protection-factor-small.svg?react';
import ReplacementIconLarge from '../../assets/svg/factors/replacement-factor-large.svg?react';
import ReplacementIconSmall from '../../assets/svg/factors/replacement-factor-small.svg?react';
import RiskIconLarge from '../../assets/svg/factors/risk-factor-large.svg?react';
import RiskIconSmall from '../../assets/svg/factors/risk-factor-small.svg?react';
import InfoPanel from '../../components/InfoPanel/InfoPanel.tsx';
import PageContainer from '../../components/PageContainer/PageContainer.tsx';
import ScoreProfileModal from '../../components/ScoreProfileModal/ScoreProfileModal.tsx';
import SectionHeading from '../../components/SectionHeading/SectionHeading.tsx';
import { useDynamicTextReplacements } from '../../hooks/use-dynamic-text-replacements.tsx';
import useResponsive from '../../hooks/use-responsive.ts';
import useScrollToBlockOnInit from '../../hooks/use-scroll-to-block-on-init.ts';
import useTrackPage from '../../hooks/use-track-page.ts';
import Button from '../../shared/components/Button/Button.tsx';
import { Card } from '../../shared/components/Card/Card.tsx';
import ReplacedText from '../../shared/components/ReplacedText/ReplacedText.tsx';
import TooltipQuestionButton from '../../shared/components/TooltipQuestionButton/TooltipQuestionButton.tsx';
import { pageHeadingCSS, pageSubheadingCSS } from '../../styles/layout.style.ts';
import { AnalyticLocation, AnalyticPageKey } from '../../types/analytics.type.ts';
import { ScoreType } from '../../types/score.type.ts';
import { hasIntegratedQuote } from '../../utils/quote.util.ts';
import { HOW_WE_CALCULATE_PROTECTION_SCORE, HOW_WE_CALCULATE_RISK_SCORE } from '../insights/ScoreCards/ScoreCards.util.ts';
import FactorList from './FactorsList/FactorsList.tsx';
import FooterCopy from './FooterCopy/FooterCopy.tsx';
import {
  activeTabCSS,
  cardCustomCSS,
  customEditButtonCSS,
  dividerCSS,
  factorHeadingContainerCSS,
  factorHeadingTextCSS,
  infoMessageCSS,
  sectionHeadingCustomCSS,
  tabsCSS,
  tabWithTagCSS,
  tagCSS,
} from './SnapshotDetailsLayout.style.ts';
import { addOrderNumber, getHeadingLabel, getScoreLabels } from './SnapshotDetailsLayout.util.tsx';

const SnapshotDetailsLayout: FC = () => {
  const { gid } = useParams() as DefaultRouteParams;
  const { isMobile } = useResponsive();
  const { data: riskScoreData } = useScoreQuery(gid, ScoreType.Risk);
  const { data: protectionScoreData } = useScoreQuery(gid, ScoreType.Protection);
  const { data: replacementScoreData } = useScoreQuery(gid, ScoreType.Replacement);
  const dynamicTextReplacements = useDynamicTextReplacements();
  const { data: accountData } = useAccountQuery(gid);
  const { data: quotesData } = useQuotesQuery(gid, !!accountData?.account.profile_completed_at);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const riskFactors = addOrderNumber(riskScoreData?.score.factors, isMobile).sort((a, b) => a.order - b.order);
  const protectionFactors = addOrderNumber(
    protectionScoreData?.score.factors,
    isMobile,
  ).sort((a, b) => a.order - b.order);
  const replacementFactors = addOrderNumber(
    replacementScoreData?.score.factors,
    isMobile,
  ).sort((a, b) => a.order - b.order);

  useTrackPage(AnalyticPageKey.SnapshotDetails);
  useScrollToBlockOnInit();

  const isProtectionScoresFilledOut = NonOptionalPolicyInfoSchema.safeParse(protectionScoreData?.policy_info).success;

  const isQuotePresent = hasIntegratedQuote(quotesData?.quotes);
  const labels = getScoreLabels(riskScoreData, protectionScoreData, isQuotePresent);

  return (
    <PageContainer pageKey={AnalyticPageKey.SnapshotDetails} footerCopy={<FooterCopy />} showBackButton>
      <h1 css={pageHeadingCSS}>Protection Snapshot Details</h1>
      <p css={pageSubheadingCSS}>The following factors impacted your risk and protection scores.</p>
      <div css={tabsCSS}>
        <div css={activeTabCSS}>Home Protection Scores</div>
        <div css={tabWithTagCSS}>
          <span>Auto Protection Scores</span>
          <span css={tagCSS}>Coming Soon</span>
        </div>
      </div>

      <div id="scroll-to-risk" />
      <Card customCSS={cardCustomCSS}>
        <>
          <SectionHeading
            title={(
              <>
                Risk Score
                {getHeadingLabel(labels.riskScoreLabel)}
              </>
            )}
            subtitle="Your level of risk can impact your home insurance rates and carrier options.
            The factors used to calculate your risk score are listed below."
            icon={isMobile ? <RiskIconSmall /> : <RiskIconLarge />}
            size="large"
            tooltip={HOW_WE_CALCULATE_RISK_SCORE?.map(hint => (
              <ReplacedText
                replacements={dynamicTextReplacements}
                template={hint}
                key={hint}
              />
            ))}
            customCSS={sectionHeadingCustomCSS}
          />

          <Divider css={dividerCSS} />
          <div css={[factorHeadingContainerCSS, factorHeadingTextCSS]}>
            Risk Factors
          </div>

          <FactorList factors={riskFactors} />
        </>
      </Card>

      <div id="scroll-to-protection" />
      <Card customCSS={cardCustomCSS}>
        <>
          <SectionHeading
            title={(
              <>
                Protection Score
                {getHeadingLabel(labels.protectionScoreLabel)}
              </>
            )}
            subtitle="Your protection score takes into account your current level of coverage and the
            steps you’ve taken to help protect your home from damage or theft."
            icon={isMobile ? <ProtectionIconSmall /> : <ProtectionIconLarge />}
            size="large"
            tooltip={HOW_WE_CALCULATE_PROTECTION_SCORE?.map(hint => (
              <ReplacedText
                replacements={dynamicTextReplacements}
                template={hint}
                key={hint}
              />
            ))}
            customCSS={sectionHeadingCustomCSS}
          />

          {(!isProtectionScoresFilledOut && isQuotePresent) && (
            <InfoPanel
              message={(
                <div css={infoMessageCSS}>
                  <span>
                    Want a more accurate insurance protection score? By sharing a few extra details, you’ll get
                    a clearer picture of your protection level and personalized insights to optimize your coverage.
                  </span>

                  <Button variant="secondary" onClick={() => setIsModalOpen(true)}>Boost My Score Accuracy</Button>
                </div>
              )}
            />
          )}

          <Divider css={dividerCSS} />
          <div css={factorHeadingContainerCSS}>
            <div css={factorHeadingTextCSS}>
              Protection Factors
              {isQuotePresent && (
                <span>
                  Your score profile is {isProtectionScoresFilledOut ? 'complete' : 'incomplete'}.
                  <TooltipQuestionButton
                    size={12}
                    content="Share some additional details to get a clearer picture of your protection level."
                  />
                </span>
              )}
            </div>

            {isQuotePresent && (
              <Button
                variant="text"
                rightIcon={<EditIcon />}
                customCSS={customEditButtonCSS}
                onClick={() => setIsModalOpen(true)}
              >
                Edit
              </Button>
            )}
          </div>

          <FactorList factors={protectionFactors} />
        </>
      </Card>

      <div id="scroll-to-replacement" />
      <Card customCSS={cardCustomCSS}>
        <>
          <SectionHeading
            title="Replacement Cost"
            subtitle="When estimating the replacement cost, the following factors significantly
            impact the estimated cost to rebuild your home."
            icon={isMobile ? <ReplacementIconSmall /> : <ReplacementIconLarge />}
            size="large"
            customCSS={sectionHeadingCustomCSS}
          />

          <Divider css={dividerCSS} />
          <div css={[factorHeadingContainerCSS, factorHeadingTextCSS]}>Replacement Cost Factors</div>

          <FactorList factors={replacementFactors} />
        </>
      </Card>

      <ScoreProfileModal
        isOpen={isModalOpen}
        data={protectionScoreData?.policy_info || {}}
        location={AnalyticLocation.ProtectionFactorsBlock}
        onClose={() => setIsModalOpen(false)}
      />
    </PageContainer>
  );
};

export default SnapshotDetailsLayout;
