import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { mq } from './media-queries';
import THEME from './theme';

export function markCSS(color: string): SerializedStyles {
  return css`
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: ${color};
    display: inline-block;
    margin-right: 8px;
    transition: all ease-in;
    border: 2px solid transparent;
    flex-shrink: 0;
  `;
}

export function futureMarkCSS(isPrimary: boolean): SerializedStyles {
  // TODO fix colors after design review by Oles
  const backgroundColor = isPrimary ? THEME.color.violet95 : THEME.color.azure95;
  const borderColor = isPrimary ? THEME.color.violet67 : THEME.color.azure50;

  return css`
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: ${backgroundColor};
    border: 2px dashed ${borderColor};
    display: inline-block;
    margin-right: 8px;
  `;
}

export function chartCSS(applyBackdrop = true): SerializedStyles {
  return css`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${applyBackdrop && css`
      ::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(3px);
        background-color: rgba(255, 255, 255, 0.40);
        bottom: -6px
      }
    `}
  `;
}

export const chartHeightCSS = css`
  height: 310px;
`;

export const addressCSS = css`
  margin-top: 9px;
  font-weight: 500;
`;

export const titleCSS = css`
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  gap: 8px;
  flex-wrap: wrap;

  ${mq[1]} {
    flex-wrap: nowrap;
    font-size: 24px;
  }
`;

export const notificationCSS = css`
  z-index: 1;
  text-align: center;
  max-width: 340px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;

  h3 {
    margin: 0;
    font-size: 16px
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
`;

export const hintCSS = css`
  margin-top: 8px;
  color: ${THEME.color.gray60};
  font-size: 14px;
  line-height: 20px;
  font-style: italic;
`;

export const highlightedDataWrapperCSS = css`
  display: flex;
  gap: 18px;
  margin-top: 14px;
  ${mq[0]} {
    gap: 30px;
  }
`;

export const highlightedDataCSS = css`
  display: flex;
  flex-direction: column;
  gap: 8px;

  font-size: 14px;
  line-height: 20px;

  > p {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }

  span {
    font-weight: 700;
  }

  > div {
    display: flex;
    align-items: center;
  }
`;
