/** TODO: Move to v2 api */
export const SETTINGS = `/api/frontend/v1/settings`;

/** TODO: Remove v1 constants */

const BASE = '/api/frontend/v2';

const API_PATH = {
  sessions: `${BASE}/sessions`,
  session: `${BASE}/sessions/:session_gid`,
  sessionTrack: `${BASE}/sessions/track`,
  sessionCurrent: `${BASE}/sessions/current`,
  sessionRefresh: `${BASE}/sessions/refresh`,
  magicLink: `${BASE}/sessions/magic_link`,
  account: `${BASE}/accounts/:gid`,
  verifyAccount: `${BASE}/accounts/:gid/verify`,
  scores: `${BASE}/accounts/:gid/scores/:type`,
  calculate_score: `${BASE}/accounts/:gid/calculate_score/:type`,
  quotes: `${BASE}/accounts/:gid/quotes`,
  suggestedQuote: `${BASE}/accounts/:gid/quotes/suggested`,
  customerChoice: `${BASE}/accounts/:gid/quotes/:quote_gid/customer_choice`,
  availableCarriers: `${BASE}/accounts/:gid/carriers`,
  digitalProfile() {
    return `${this.account}/profile`;
  },
  recentPolicy() {
    return `${this.account}/recent_policy`;
  },
  leads: `${BASE}/accounts/:gid/leads`,
  disclosures: `${BASE}/disclosures`,
  proposals: `${BASE}/proposals?person_gid=:person_gid&engagement_gid=:engagement_gid`,
  proposal: `${BASE}/proposals/:gid`,
  trackProposalOpened: `${BASE}/proposals/:gid/track_opened`,
  proposalUserPresence: `${BASE}/proposals/:gid/user_presence`,
  person: `${BASE}/people/:gid`,
  personAssets: `${BASE}/people/:gid/assets`,
  relatedPeople: `${BASE}/people/:gid/related_people`,
  agentContactInfo: `${BASE}/agents/:gid/contact_info`,
  soldCustomersCount: `${BASE}/agents/:gid/sold_customers_count`,
  personLoans: `${BASE}/people/:gid/loans`,
  maticContent: `https://matic.com/wp-json/wp/v2/posts?_embed=wp:featuredmedia,
  wp:term&_fields=title,link,excerpt,_links,_embedded&per_page=30&categories=:categoryId`,
  savings: `${BASE}/people/:person_gid/policies/savings`,
  publicRecentPolicy: `${BASE}/people/:person_gid/policies/recent`,
  history() {
    return `${this.account}/history`;
  },
  premiumHistory() {
    return `${this.history()}/premium`;
  },
  replacementCostHistory() {
    return `${this.history()}/replacement_cost`;
  },
  quotesOverTimeHistory() {
    return `${this.history()}/quotes_over_time`;
  },
  fullStorySessions: `${BASE}/proposals/:proposal_gid/full_story_sessions`,
};

export default API_PATH;
