import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';

import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import type { VerifyFormProps, VerifyFormState } from './VerifyForm.type.ts';
import { isAxiosError } from 'axios';

import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import useAccountQuery from '../../../api/account/use-account-query.ts';
import useVerify from '../../../api/account/use-verify.ts';
import DateInputQuestion from '../../../components/Question/DateInputQuestion/DateInputQuestion.tsx';
import TextInputQuestion from '../../../components/Question/TextInputQuestion/TextInputQuestion.tsx';
import useAnalytics from '../../../hooks/use-analytics.ts';
import Button from '../../../shared/components/Button/Button.tsx';
import ValidationError from '../../../shared/components/ValidationError/ValidationError.tsx';
import { AnalyticEventStatus } from '../../../types/analytics.type.ts';
import {
  PERSON_AGE_VALIDATION,
  REQUIRED_VALIDATION,
  ZIP_CODE_VALIDATION,
} from '../../../utils/question-validations.util.ts';

import { buttonCSS, formInnerCSS, validationErrorCSS } from './VerifyForm.style.ts';
import { DEFAULT_ERROR_MESSAGE, VERIFICATION_ERROR_CODE, VERIFICATION_ERROR_MESSAGE } from './VerifyForm.util.ts';

const VerifyForm: FC<VerifyFormProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: accountData, refetch: refetchUserQuery } = useAccountQuery(gid);

  const { mutateAsync: verify } = useVerify(gid);

  const { track } = useAnalytics();

  const methods = useForm<VerifyFormState>({
    shouldUnregister: false,
    shouldFocusError: true,
    mode: 'onTouched',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const noAuthAttemptsLeft = Boolean(
    accountData?.account && accountData.account.available_authorization_attempts_count < 1,
  );
  const showVerificationError = !!submitError || noAuthAttemptsLeft;

  const onFormChange = () => {
    if (submitError) {
      setSubmitError(null);
    }
  };

  const onSubmit: SubmitHandler<VerifyFormState> = async (data) => {
    if (isSubmitting) {
      return;
    }
    const availableAuthorizationAttempts = accountData?.account.available_authorization_attempts_count || -1;

    setIsSubmitting(true);
    try {
      await verify(data);
      track('Account Verified', {
        status: AnalyticEventStatus.Success,
        available_attempts_count: availableAuthorizationAttempts,
      });
    }
    catch (error) {
      await refetchUserQuery();
      const message = (isAxiosError(error) && String(error?.response?.data.message)) || '';
      track('Account Verified', {
        status: AnalyticEventStatus.Error,
        available_attempts_count: message === VERIFICATION_ERROR_CODE
          ? availableAuthorizationAttempts - 1
          : availableAuthorizationAttempts,
        fail_reason: message,
      });
      setSubmitError(message);
    }
    setIsSubmitting(false);
  };

  return (
    <div css={customCSS}>
      <FormProvider {...methods}>
        <form onChange={onFormChange} onSubmit={methods.handleSubmit(onSubmit)}>
          <div css={formInnerCSS}>
            <TextInputQuestion
              questionKey="last_name"
              label="Last Name"
              placeholder="Doe"
              dense
              gridWidth={8}
              validations={[REQUIRED_VALIDATION]}
              trackingForbidden
            />
            <DateInputQuestion
              questionKey="date_of_birth"
              label="Date of Birth"
              dense
              gridWidth={4}
              validations={[REQUIRED_VALIDATION, ...PERSON_AGE_VALIDATION]}
              trackingForbidden
            />
            <TextInputQuestion
              questionKey="zip"
              label="Zip code of your primary address"
              placeholder="5-digit zip code"
              dense
              gridWidth={12}
              validations={[REQUIRED_VALIDATION, ZIP_CODE_VALIDATION]}
              trackingForbidden
            />
          </div>

          <Button
            customCSS={buttonCSS}
            type="submit"
            size="large"
            isLoading={isSubmitting || !accountData}
            fullWidth
            disabled={noAuthAttemptsLeft}
          >
            Continue
          </Button>

          <ValidationError customCSS={validationErrorCSS} visible={showVerificationError}>
            { submitError === VERIFICATION_ERROR_CODE || noAuthAttemptsLeft
              ? VERIFICATION_ERROR_MESSAGE
              : DEFAULT_ERROR_MESSAGE}
          </ValidationError>
        </form>
      </FormProvider>
    </div>
  );
};

export default VerifyForm;
