import type { FC } from 'react';
import type { Account } from '../../types/account.type.ts';
import { isAxiosError } from 'axios';

import { useEffect, useRef, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router';
import useAccountQuery from '../../api/account/use-account-query.ts';
import useAuthenticate from '../../api/authentification/use-authenticate.ts';
import { ANALYTICS_SESSION_GID } from '../../constants/browser-storage.ts';
import ROUTES from '../../constants/routes.ts';
import { useAnalyticsSessionGidCheck } from '../../hooks/use-analytics-session-gid-check.ts';
import { useUtmParams } from '../../hooks/use-utm-params.ts';
import FullPageLoader from '../../shared/components/FullPageLoader/FullPageLoader.tsx';
import { AccountCreationMethod } from '../../types/account.type.ts';
import { reportErrorMessage } from '../../utils/error.util.ts';
import { canAuthenticate, getVerifiedUserNavigationUrl } from './Authenticate.util.ts';

export interface AuthErrorData {
  message: string;
  isAxios: boolean;
  responseStatus?: number | null;
}

const Authenticate: FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const accountGid = searchParams.get('gid');
  const personGid = searchParams.get('person_gid');
  const marketingEngagementGid = searchParams.get('engagement_gid');
  const targetPage = searchParams.get('target_page');
  const sessionGidParam = searchParams.get('session_gid');
  const newAccountParam = searchParams.get('new_account'); // Account is created from Sign Up page
  const utmParams = useUtmParams();

  const sessionGigLocalStorage = localStorage.getItem(ANALYTICS_SESSION_GID);

  const authSend = useRef(false);
  const [accountResponse, setAccountResponse] = useState<Account | null>(null);
  const [authenticateError, setAuthenticateError] = useState<AuthErrorData>();
  const { data: accountData, isFetched: isAccountFetched } = useAccountQuery(accountGid, false);
  const account = accountData?.account || accountResponse;

  const { mutateAsync: authenticate } = useAuthenticate();

  const { sessionGid } = useAnalyticsSessionGidCheck(
    sessionGidParam || sessionGigLocalStorage,
    null,
    utmParams,
  );

  useEffect(() => {
    const call = async (
      tokenValue: string,
      gidValue: string | null,
      personGidValue: string | null,
      sessionGidValue: string | null,
      marketingEngagementGidValue?: string | null,
    ) => {
      try {
        const response = await authenticate({
          token: tokenValue,
          gid: gidValue,
          person_gid: personGidValue,
          engagement_gid: marketingEngagementGidValue,
          session_gid: sessionGidValue,
        });
        setAccountResponse(response.account);
      }
      catch (error) {
        if (isAxiosError(error)) {
          setAuthenticateError({
            message: error.message,
            isAxios: true,
            responseStatus: error.response?.status || null,
          });
        }
        else {
          setAuthenticateError({
            message: error instanceof Error ? error.message : 'Unknown',
            isAxios: false,
          });
        }
      }
    };

    if (canAuthenticate(
      token,
      accountGid,
      personGid,
      sessionGid,
      isAccountFetched,
      !!accountData?.account,
      authSend.current,
    )) {
      authSend.current = true;
      call(token as string, accountGid, personGid, sessionGid, marketingEngagementGid);
    }
  }, [
    authenticate,
    accountGid,
    personGid,
    marketingEngagementGid,
    newAccountParam,
    token,
    sessionGid,
    isAccountFetched,
    accountData?.account,
  ]);

  if (!token || !(accountGid || personGid)) {
    reportErrorMessage(`No token or account gid or person gid found in URL when authenticate.
     token=${token}, accountGid=${accountGid}, personGid=${personGid}`);
    return <Navigate to={ROUTES.problem} replace />;
  }

  if (authenticateError) {
    const params = new URLSearchParams({
      gid: accountGid || '',
      person_gid: personGid || '',
      target_page: targetPage || '',
      new_account: newAccountParam || '',
    });

    return authenticateError.isAxios && authenticateError.responseStatus === 401
      ? (
          <Navigate to={`${ROUTES.linkExpired}?${params.toString()}`} replace />
        )
      : <Navigate to={ROUTES.problem} replace />;
  }

  if (!account || !sessionGid) {
    return <FullPageLoader />;
  }

  if (account.creation_method === AccountCreationMethod.SelfCreated && !account.profile_completed_at) {
    return (
      <Navigate to={ROUTES.digitalProfileIntro.replace(':gid', account.gid)} replace />
    );
  }

  return account?.verified
    ? <Navigate to={getVerifiedUserNavigationUrl(account.gid, targetPage, newAccountParam)} replace />
    : (
        <Navigate
          to={`${ROUTES.verify.replace(':gid', account.gid)
          }?target_page=${targetPage}`}
          replace
        />
      );
};

export default Authenticate;
