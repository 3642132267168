import type { FC } from 'react';

import type { SpecificQuestionProps } from '../Question.type.ts';
import QuestionWrapper from '../../QuestionWrapper/QuestionWrapper';
import DateInput from './DateInput';

const DateInputQuestion: FC<SpecificQuestionProps> = ({
  questionKey,
  label,
  hideLabel,
  description,
  hint,
  icon,
  isDisabled,
  placeholder = 'mm-dd-yyyy',
  mask = '##-##-####',
  validations,
  gridWidth,
  dense,
  trackingForbidden,
}) => {
  return (
    <QuestionWrapper
      questionKey={questionKey}
      label={label}
      hideLabel={hideLabel}
      hint={hint}
      description={description}
      icon={icon}
      isDisabled={isDisabled}
      gridWidth={gridWidth}
      inputProps={{
        name: questionKey,
        inputId: questionKey,
        isDisabled,
        placeholder,
        mask,
        validations,
        renderDropdownInBody: false,
        trackingForbidden,
        dense,
      }}
      inputComponent={DateInput}
    />
  );
};

export default DateInputQuestion;
