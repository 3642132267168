import type { FC } from 'react';
import LightBulbIcon from '../../assets/svg/light-bulb.svg?react';
import MaticLogo from '../../assets/svg/matic-logo.svg?react';
import useTrackPage from '../../hooks/use-track-page.ts';
import Button from '../../shared/components/Button/Button';
import Container from '../../shared/components/Container/Container.tsx';
import { AnalyticPageKey } from '../../types/analytics.type.ts';
import CheckIn from './CheckIn/CheckIn';
import HowItWorksCard from './HowItWorksCard/HowItWorksCard';
import SignUpForm from './SignUpForm/SignUpForm';
import {
  cardsWrapperCSS,
  centerTextCSS,
  containerCSS,
  customButtonCSS,
  formWrapperCSS,
  howItWorksContainerCSS,
  logoCSS,
  marketingWrapperCSS,
  sectionHeadingCSS,
} from './SignUpLayout.style.ts';
import { HOW_IT_WORKS_DATA, TOOLS_TO_OPTIMIZE_DATA } from './SignUpLayout.utils.tsx';
import ToolsToOptimize from './ToolsToOptimizeCard/ToolsToOptimizeCard';

const SignUpLayout: FC = () => {
  useTrackPage(AnalyticPageKey.SignUp);

  const scrollToForm = () => {
    const elem = document.getElementById('scroll-to-sign-up-form');

    if (elem)
      window?.scrollTo({ behavior: 'smooth', top: elem.offsetTop - 100 });
  };

  return (
    <Container customCSS={containerCSS}>
      <header>
        <MaticLogo css={logoCSS} />
      </header>
      <section css={formWrapperCSS}>
        <SignUpForm />
        <LightBulbIcon />
      </section>
      <section css={marketingWrapperCSS}>
        <h2 css={[sectionHeadingCSS, centerTextCSS]}>Free tools to optimize your rates and coverage</h2>
        <div css={cardsWrapperCSS}>
          {TOOLS_TO_OPTIMIZE_DATA.map(tool => (
            <ToolsToOptimize
              {...tool}
              key={tool.title}
            />
          ))}
        </div>

        <Button
          customCSS={customButtonCSS}
          size="large"
          onClick={scrollToForm}
        >
          Sign Up Now
        </Button>

        <h2 css={[sectionHeadingCSS, centerTextCSS]}>How it Works</h2>
        <div css={howItWorksContainerCSS}>
          {HOW_IT_WORKS_DATA.map(howItWorks => (
            <HowItWorksCard
              {...howItWorks}
              key={howItWorks.title}
            />
          ))}
        </div>

        <CheckIn onActionClicked={scrollToForm} />
      </section>
    </Container>
  );
};

export default SignUpLayout;
