import type { AccountResponse } from '../types/account.type.ts';
import type {
  DataItem,
  HistoryData,
  PremiumHistoryResponse,
  QuotesOverTimeHistoryItem,
  QuotesOverTimeHistoryResponse,
  ReplacementCostHistoryItem,
  ReplacementCostHistoryResponse,
  TransformedHistoryData,
  TransformedReplacementCostData,
} from '../types/history.type';
import type { QuotesResponse } from '../types/quote.type';

import * as d3 from 'd3';
import THEME from '../styles/theme';
import {
  HistoryDataStatuses,
} from '../types/history.type';
import { QuotingStatus } from '../types/quote.type';

export const chartMainColors = d3.scaleOrdinal<string>()
  .domain(['0', '1'])
  .range([THEME.color.violet87, THEME.color.azure50]);

export const chartFutureDataColor = d3.scaleOrdinal<string>()
  .domain(['0', '1'])
  .range([THEME.color.violet95, THEME.color.azure95]);

export const chartFutureDataBorderColor = d3.scaleOrdinal<string>()
  .domain(['0', '1'])
  .range([THEME.color.violet67, THEME.color.azure50]);

export function setBarAttributes(
  selection: d3.Selection<SVGRectElement, any, any, any>,
  x: d3.ScaleBand<string>,
  y: d3.ScaleLinear<number, number, never>,
  height: number,
  currentYear: string,
  padding: number,
  duration: number,
) {
  selection
    .attr('class', 'bar')
    .attr('x', d => x(`${d.key}`)!)
    .attr('y', height - 1)
    .attr('width', x.padding(padding).bandwidth())
    .attr('height', 0)
    .attr('fill', d => (d.year > currentYear
      ? chartFutureDataColor(`${d.key}`)
      : chartMainColors(`${d.key}`)) as string)
    .attr('stroke', d => (d.year > currentYear ? chartFutureDataBorderColor(`${d.key}`) : 'none') as string)
    .attr('stroke-dasharray', d => (d.year > currentYear ? '4,2' : 'none'))
    .attr('stroke-width', 2)
    .transition()
    .duration(duration)
    .attr('y', d => y(d.value) - 1)
    .attr('height', d => height - y(d.value))
    .attr('pointer-events', 'none');
}

export function formatSuffix(d: number, currentYear: number, returnEmptySuffix: boolean): string {
  if (returnEmptySuffix)
    return '';

  return d === currentYear ? `(Now)` : d > currentYear ? `(Est.)` : ``;
}

function transformHistoryData<T>(
  history: HistoryData<T>,
  filterFn: (premiums: T) => boolean,
  mapFn: (year: string, premiums: T) => { year: string; values: any[] },
  defaultPremiums: T,
): { year: string; values: any[] }[] {
  let hasFoundValid = false;
  const transformed: { year: string; values: any[] }[] = [];

  for (const [year, premiums] of Object.entries(history)) {
    if (!hasFoundValid && filterFn(premiums)) {
      hasFoundValid = true;
    }

    if (hasFoundValid) {
      transformed.push(mapFn(year, premiums));
    }
  }

  if (transformed.length === 0) {
    const currentYear = new Date().getFullYear().toString();
    const currentYearPremiums = history[currentYear] || defaultPremiums;
    return [mapFn(currentYear, currentYearPremiums)];
  }

  return transformed;
}

export function transformPremiumHistoryData(history: HistoryData): TransformedHistoryData {
  return transformHistoryData(
    history,
    premiums => !!premiums.policy_premium || !!premiums.quote_premium,
    (year, premiums) => ({
      year,
      values: [premiums.policy_premium, premiums.quote_premium].map(Number),
    }),
    { policy_premium: 0, quote_premium: 0 },
  );
}

export function transformReplacementCostData(
  history: HistoryData<ReplacementCostHistoryItem>,
): TransformedReplacementCostData {
  return transformHistoryData(
    history,
    premiums => !!premiums.replacement_cost_min || !!premiums.replacement_cost_max,
    (year, premiums) => ({
      year,
      values: [
        {
          min: premiums.replacement_cost_min || 0,
          max: premiums.replacement_cost_max || 0,
        },
        premiums.replacement_cost || 0,
      ],
    }),
    {
      replacement_cost_min: 0,
      replacement_cost_max: 0,
      replacement_cost: 0,
    },
  ) as TransformedReplacementCostData;
}

export function transformQuotesOverTimeHistoryData(
  history: HistoryData<QuotesOverTimeHistoryItem>,
): TransformedHistoryData {
  return transformHistoryData(
    history,
    premiums => !!premiums,
    (year, premiums) => ({
      year,
      values: [premiums].map(Number),
    }),
    0,
  );
}

export function getLatestYearObject<T extends DataItem>(data: T[]): T {
  const latestYear = Math.max(...data.map(d => Number(d.year))).toString();
  return data.find(item => item.year === latestYear)!; // return the object with the latest year
}

export function getChartState(
  quotingStatus?: QuotingStatus,
  accountData?: AccountResponse,
  quotesData?: QuotesResponse,
  historyData?: PremiumHistoryResponse | ReplacementCostHistoryResponse | QuotesOverTimeHistoryResponse,
): HistoryDataStatuses {
  const dpCompleted = accountData?.account.profile_completed_at;

  switch (true) {
    case Boolean(dpCompleted && historyData?.history):
      return HistoryDataStatuses.Ready;
    case quotingStatus === QuotingStatus.Pending:
      return HistoryDataStatuses.Pending;
    case !accountData:
    case dpCompleted && !quotesData:
    case quotingStatus === QuotingStatus.Finished && !historyData:
    default:
      return HistoryDataStatuses.Unknown;
  }
}
